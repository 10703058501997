control-key-size = 45px

ul.controls-map
    debullet()
    margin 0 20px
    overflow auto

    li.control
        padding-left control-key-size + 5px
        position relative
        height control-key-size
        margin-bottom 5px
        box-sizing border-box
        padding-right 40px

        a.delete
            circle(20px)
            border-radius()
            padding 0
            position absolute
            right 10px
            top 50%
            transform translateY(-50%)
        
    li.add
        margin-top 20px
        margin-bottom 20px

    .key
        border-radius()
        width control-key-size
        height control-key-size
        line-height control-key-size
        text-align center
        background color-primary
        position absolute
        left 0
        top 0
        font-weight bold
        text-shadow rgba(0, 0, 0, .2) 0 1px 1px
        text-transform uppercase

        &.small
            font-size 8px

        &.key-up, &.key-right, &.key-down, &.key-left
            font-size 20px

            &:after
                icon()

            span
                display none

        &.key-up:after
            @extends .icon-caret-up:before

        &.key-right:after
            @extends .icon-caret-right:before

        &.key-down:after
            @extends .icon-caret-down:before

        &.key-left:after
            @extends .icon-caret-left:before

        &:before
            absolute-cover()
            border-radius()
            content ''
            box-sizing border-box
            border 7px outset lighten(color-primary, 10)
            mix-blend-mode overlay
            opacity .6

    .inner
        position relative
        height 100%

    input
        font-family font-code
        position absolute
        width 100%
        left 0
        top 50%
        transform translateY(-50%)

.modal-select-key
    padding 50px

    input
        position absolute
        left 0
        top 0
        opacity .0001