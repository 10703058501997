.level-editor
    
    .selection-highlight, .selection-preview
        border 2px solid rgba(#fff, .8)
        margin -2px
        box-shadow rgba(#000, .4) 0 1px 2px 0
        pointer-events none

    .selection-highlight
        position absolute

    .selection-preview
        position fixed
        background rgba(color-bg, .2)
        opacity 1
        z-index 3
        transition opacity .2s
        opacity 0
        z-index 5

        &.show
            opacity 1

        .behaviours, .items
            absolute-cover()

        .tile
            opacity .5
            width editor-tile-size
            height @width