hamburger-closed(scale = 1)
    width (60px * scale)
    height (45px * scale)
    position relative
    transform rotate(0deg)
    transition .5s ease-in-out
    cursor pointer

    span
        display block
        position absolute
        height (9px * scale)
        width 50%
        background color-white
        opacity 1
        transform rotate(0deg)
        transition .25s ease-in-out

        &:nth-child(even)
            border-radius(0 (9px * scale) (9px * scale) 0)
            left 50%

        &:nth-child(odd)
            border-radius((9px * scale) 0 0 (9px * scale))
            left 0

        &:nth-child(1), &:nth-child(2)
            top 0

        &:nth-child(3), &:nth-child(4)
            top (18px * scale)

        &:nth-child(5), &:nth-child(6)
            top (36px * scale)

hamburger-open(scale = 1)

    span
    
        &:nth-child(1), &:nth-child(6)
            transform rotate(45deg)

        &:nth-child(2), &:nth-child(5)
            transform rotate(-45deg)

        &:nth-child(1)
            left (5px * scale)
            top (7px * scale)

        &:nth-child(2)
            left "calc(50% - %s)" % (5px * scale)
            top (7px * scale)

        &:nth-child(3)
            left -50%
            opacity 0

        &:nth-child(4)
            left 100%
            opacity 0

        &:nth-child(5)
            left (5px * scale)
            top (29px * scale)

        &:nth-child(6)
            left "calc(50% - %s)" % (5px * scale)
            top (29px * scale)