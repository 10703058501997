page-width()
    max-width 1020px
    margin-left auto
    margin-right auto

absolute-cover(position-type = absolute)
    position position-type
    left 0
    top 0
    width 100%
    height 100%

border-radius(radius = base-radius)
    border-radius radius

debullet()
    list-style none
    padding-left 0

    li
        margin-left 0

smooth-font(state = true)
    if state == false
        font-smoothing subpixel-antialiased
        -moz-osx-font-smoothing auto
    else
        font-smoothing antialiased
        -moz-osx-font-smoothing grayscale

circle(size = 10px, color = none)
    width size
    height size
    border-radius size
    line-height size
    text-align center
    display inline-block

    if color
        background-color color

no-select()
    -webkit-touch-callout none
    -webkit-user-select none
    -khtml-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none

filter(value)
    -webkit-filter value
    filter value

vertical-rhythm(spacing = 20px)
    margin-bottom spacing

    &:last-child
        margin-bottom 0

icon()
    smooth-font()
    font-family 'icons'
    speak none
    font-style normal
    font-weight normal
    font-variant normal
    text-transform none
    display inline-block

center-horizontally()
    left 50%
    transform translateX(-50%)

center-vertically()
    left 50%
    transform translateX(-50%)

center-absolute()
    left 50%
    top 50%
    transform translate(-50%, -50%)

sharp-scale()
    image-rendering optimizeSpeed
    image-rendering -moz-crisp-edges
    image-rendering -o-crisp-edges
    image-rendering -webkit-optimize-contrast
    image-rendering optimize-contrast
    image-rendering crisp-edges
    image-rendering pixelated
    -ms-interpolation-mode nearest-neighbor