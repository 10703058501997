#view.view-splash

    section
        overflow hidden
        position relative
        padding-left 20px
        padding-right @padding-left
        box-sizing border-box

    .buttons .button
        position relative
        overflow hidden
        padding-left 50px
        margin-bottom 0

        i
            position absolute
            font-size 35px
            left 10px
            top 10px
            opacity .2

    .intro-copy
        margin-bottom 20px

        h3
            margin-bottom 0

    .text-make, .text-learn, .text-play
        font-weight bold

    .text-make
        color lighten(color-primary, 20)

    .text-learn
        color lighten(color-blue, 20)

    .text-play
        color lighten(color-success, 20)

    section#hero
        text-align center
        width 100%
        background-color color-bg
        background-image linear-gradient(#cdf1fc, rgba(#cdf1fc, .8), rgba(#7ed7f0, .5), rgba(#7ed7f0, 0))

        .page-width
            padding 30px 0

        .logo
            illustration-fixed('splash/logo')
            margin auto
            position relative
            z-index 4

        .illustration-wrap
            margin auto

        .illustration
            illustration-fluid('splash/illustration')
            margin auto
            margin-top -40px
            position relative
            z-index 2
            margin-bottom 30px

        .cloud
            position absolute
            left -300px

            &.cloud-01
                illustration-fixed('splash/clouds-01')
                animation cloud 23s infinite linear
                top 120px
                z-index 3

            &.cloud-02
                illustration-fixed('splash/clouds-02')
                animation cloud 27s infinite linear 6s
                top 220px
                z-index 1

            &.cloud-03
                illustration-fixed('splash/clouds-03')
                animation cloud 25s infinite linear 8s
                top 200px
                z-index 3

        button i
            font-size 23px
            line-height 0
            position relative
            top 4px
            margin-left -15px
            margin-right 5px

    section#play
        background darken(color-bg, 10)

        h3 strong
            color color-success

        .copy
            margin-top 30px

    section#learn
        background darken(color-bg, 20)

        h3 strong
            color color-blue

        .copy
            margin-top 130px

    section#make
        background darken(color-bg, 10)

        h3 strong
            color color-primary

        .copy
            margin-top 60px

    .judoka-face
        bg-image('ui/judoka-face', cover, center center, no-repeat)
        width 100px
        height @width
        margin auto
        margin-bottom 10px

    #get-started
        background-image radial-gradient(rgba(color-white, .2), 800px rgba(color-white, 0))

    @media (max-width: mobile-breakpoint)
        text-align center

        .intro-copy h3
            margin-bottom 20px

        .button
            margin 10px 5px
            padding 15px 20px