.game-finished-overlay
    absolute-cover()
    background rgba(color-black, .8)
    z-index 2

.game-finished
    absolute-cover()
    background color-black
    z-index 2
    animation pop-in 1s

    .inner
        position absolute
        transform translateY(-50%)
        left 0
        top 50% 

    h2
        text-align center
        font-size 30px

    .tinkerer
        bg-image('ui/tinkerer', contain, center center, no-repeat)
        height 100px
        margin-top 30px
        margin-bottom 20px
        margin-right -10px

    .bubble
        border-radius()
        smooth-font()
        font-size 18px
        background color-white
        color color-black
        margin 20px
        padding 15px 20px 20px
        font-weight bold
        position relative
        text-align center

        h3
            color color-black
            font-weight bold

        &:after
            tooltip-tail(top, color-white)
            position absolute
            content ''

        .buttons
            margin-top 20px

        button, .button
            smooth-font(false)
            text-transform uppercase
            margin-bottom 20px