cell-size = 150px
connector-size = 30px

connector-color(base-color)
    background base-color

    &:hover
        background lighten(base-color, 10)

action-color(base-color)
    background mix(color-bg, base-color, 70%)

    &:hover
        background mix(color-bg, base-color, 40%)

.level-editor-world
    background color-black
    padding 30px
    text-align center
    box-sizing border-box
    display table
    width 100%
    padding-left toolbar-size

    i
        top 3px

    .inner
        display table-cell
        vertical-align middle

    .cluster
        border-radius()
        display inline-block
        padding 10px
        vertical-align middle

        .row
            margin 0
            height cell-size
            display table-row

        .cell
            display inline-block
            min-width cell-size
            max-width cell-size
            height cell-size
            position relative
            margin 0 0 -1px -1px
            transition opacity .2s
            display table-cell
            padding 0

            img
                sharp-scale()

            a.select
                absolute-cover()
                cursor pointer
                z-index 2

            &:after
                absolute-cover()
                content ''
                z-index 1

            &.selected img
                opacity 1

            &.available

                img
                    opacity 1

                &:after
                    box-shadow rgba(color-white, .5) 0 0 0 2px inset

            &.add
                border-radius()
                background lighten(color-bg, 10)
                line-height cell-size
                color color-white
                font-size 40px
                opacity .5

                i
                    top 5px

                &:after
                    border-radius()

                &:hover, &.available
                    opacity 1

            img
                width cell-size
                height cell-size
                position absolute
                left 0
                top 0
                box-shadow rgba(color-black, .1) 0 0 0 1px
                opacity .8

            &:hover

                .connector, .actions
                    display inline-block

            .label
                border-radius()
                ellipsis()
                smooth-font()
                font-size 9px
                position absolute
                left 50%
                transform translateX(-50%)
                top 73%
                margin-top -7px
                max-width 100%
                padding 3px 6px
                box-sizing border-box
                background rgba(color-black, .8)
                text-align center
                color color-white
                font-family font-code
                transition opacity .2s

            .actions
                border-radius()
                position absolute
                left 50%
                top 50%
                transform translateX(-50%) translateY(-50%)
                z-index 2
                transition opacity .2s
                display none

                a
                    display inline-block
                    color color-white
                    width 25px
                    line-height @width
                    text-align center
                    transition background .2s
                    position relative

                    &:first-child
                        border-radius(base-radius 0 0 base-radius)

                    &:last-child
                        border-radius(0 base-radius base-radius 0)

                    &.action-draw
                        action-color(lighten(color-green, 10))

                    &.action-delete
                        action-color(lighten(color-red, 10))

                    &.action-edit
                        action-color(lighten(color-bg, 10))

            .connector
                circle(connector-size, color-bg)
                font-size 12px
                line-height connector-size - 2px
                position absolute
                margin-left -(connector-size / 2)
                margin-top -(connector-size / 2)
                color color-white
                display none
                transition opacity .2s
                z-index 2
                cursor pointer
                transition background .2s

                &.connector-add
                    connector-color(mix(color-bg, color-success, 70%))
                    
                &.connector-remove
                    connector-color(mix(color-bg, color-danger, 70%))
                    top 50%
                    left 50%

                &.connector-top
                    left 50%
                    top 0

                &.connector-right
                    left 100%
                    top 50%

                &.connector-bottom
                    left 50%
                    top 100%

                &.connector-left
                    left 0
                    top 50%

                &.active
                    connector-color(color-grey)
                    display inline-block

                &.connector-connectable
                    connector-color(mix(color-bg, color-orange, 70%))
                    left 50%
                    top 50%
                    display inline-block

                &.connector-cancel
                    connector-color(mix(color-bg, color-red, 30%))
                    display inline-block

            .connection
                border-radius()
                position absolute
                background color-white
                margin 3px
                width 10px
                font-size 9px
                color color-black

                &.connection-top
                    left 50%
                    top 0
                    margin-left -5px

                &.connection-right
                    right 0
                    top 50%
                    margin-top -5px

                &.connection-bottom
                    left 50%
                    bottom 0
                    margin-left -5px

                &.connection-left
                    left 0
                    top 50%
                    margin-top -5px