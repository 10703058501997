.tiles-palette, .tiles-palette-chromeless
    no-select()
    min-width editor-tile-size * 6

    .tile
        display block
        display inline-block
        width (100% / 6)
        width editor-tile-size
        height @width
        position relative
        opacity .8

        &:after
            border 0
            pointer-events none

        &.selected .tile
            opacity 1

        &.disabled
            opacity .1
            filter grayscale()

.tiles-palette
    clearfix()
    no-select()
    vertical-rhythm()
    background lighten(color-bg, 5%)
    width palette-size
    border border-grid
    position relative

    &:before
        absolute-cover()
        content ''
        display block
        pointer-events none

    .holder
        position relative
        top -1px

.tiles-palette-chromeless
    vertical-rhythm()
    clearfix()
    margin -4px
    margin-left 0
    margin-bottom 17px

    .tile
        margin 3px
        background-color rgba(color-white, .2)

        &.disabled
            opacity .2
            filter none

        &, &:after
            border-radius()

        &.selected
            background-color rgba(color-white, .4)
            box-shadow rgba(#000, .4) 0 1px 2px 0

            &:before
                absolute-cover()
                border 2px solid color-white
                content ''
                box-sizing border-box

    canvas.tile.selected
        box-shadow color-white 0 0 0 2px inset