button-color(base-color = color-button-base)
    background mix(color-button-base, base-color)

    &.highlight
        background base-color

    &:hover:not(.disabled)
        background lighten(base-color, 5)

button, a.button
    border-radius()
    button-color()
    vertical-rhythm()
    border 0
    padding 10px 20px
    color color-type-body
    font-weight 100
    font-family font-body
    font-size 14px
    box-sizing border-box
    cursor pointer
    transition background .2s
    margin-right 10px
    margin-left 0
    display inline-block

    &.primary
        button-color color-primary

    &.secondary
        button-color color-blue

    &.success
        button-color color-success

    &.danger
        button-color color-danger

    &.warning
        button-color darken(color-warning, 10)

    &.disabled
        opacity .3
        cursor default

    &:last-child
        margin-right 0

    &.block
        width 100%

    &.highlight
        box-shadow 0 -3px 0 0 rgba(0, 0, 0, .1) inset

    &.large
        padding 18px 35px
        font-size 16px
        text-transform uppercase