.tooltip-hover
    tooltip('top', 5px)
    border-radius()
    pointer-events none
    position absolute
    left 50%
    top 100%
    transform translateX(-50%)
    margin 10px 0
    background color-tooltip
    color color-type-body
    padding 10px 20px
    opacity 0
    transition opacity .2s
    z-index 999

    &.bottom
        tooltip('bottom', 5px)
        position absolute
        top auto
        bottom 100%

*:hover > .tooltip-hover
    opacity 1