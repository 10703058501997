.multi-selection, .items-list
    debullet()
    clearfix()
    font-size 13px
    margin 0
    margin -20px
    margin-bottom 20px
    background transparent

    &.locked li
        color rgba(color-white, .15)

    &:last-child
        margin-bottom -20px

    &.mono
        smooth-font()
        font-size 12px

    li
        padding 8px 15px
        margin-top 1px
        background darken(color-bg, 15)
        position relative
        cursor pointer
        transition color .2s

        &.active
            background darken(color-bg, 20)
            color rgba(color-type-body, .4)

            &:before
                content '\2022'

        &:last-child
            border-bottom 0

    .actions
        position absolute
        right 5px
        top 5px

        a
            display inline-block
            background color-bg
            opacity .3
            color color-type-body
            width 20px
            line-height @width
            text-align center
            margin-left 1px
            transition background .2s, opacity .2s

            i
                position relative
                top 2px

            &.active
                opacity 1

            &.action-solo.active
                background color-red
                opacity 1

            &.action-delete:hover
                background: color-danger
                opacity 1

            &.action-edit:hover
                background: color-info
                opacity 1

            &:first-child
                border-radius(base-radius 0 0 base-radius)

            &:last-child
                border-radius(0 base-radius base-radius 0)

            &:first-child:last-child
                border-radius()

    &.horizontal
        margin 0
        vertical-rhythm()
        background transparent
        text-align center

        li
            border-radius()
            background darken(color-bg, 15)
            display inline-block
            margin 0 2.5px
            padding-left 15px
            padding-right @padding-left

            &:before
                display none

            &:hover
                background darken(color-bg, 13)

            &.active
                background darken(color-bg, 10)

.multi-selection

    &:not(.locked) li:hover
        background darken(color-bg, 13)

    li
        padding-left: 10px
        cursor pointer

        &:before
            content ''
            display inline-block
            margin-right 5px
            opacity 1
            width 10px
            text-align center