.tile-picker
    position: relative

    .picker-display .tile
        width editor-tile-size
        height @width
        float none
        background-color rgba(color-white, .1)
        margin-bottom 5px
        cursor pointer
        transition background .2s

        &:hover
            background-color rgba(color-white, .2)

        &, &:after
            border-radius()

    .picker-tooltip
        border-radius()
        pointer-events auto
        background rgba(color-tooltip, .9)
        padding 10px
        position: absolute
        left 50%
        top 50%
        transform translate(-50%, -50%)
        z-index 3

        .tiles-palette
            background rgba(color-white, .1)
            cursor pointer

            .tile
                opacity 1
                cursor pointer

                &:hover:after
                    border 2px solid color-white

                &:after
                    border 0