.editor-challenge
    no-select()

canvas.focus-canvas
    position fixed
    left 0
    top 0
    z-index 20
    pointer-events none
    no-select()

    &.show
        opacity .6

.focus-highlighter
    border-radius(2px)
    position fixed
    border 3px solid color-white
    z-index 21
    box-sizing border-box
    pointer-events none
    box-shadow rgba(0, 0, 0, .4) 0 1px 2px 1px
    transform-origin center center

    &.show
        opacity 1

canvas.focus-canvas, .focus-highlighter
    transition opacity .2s, transform 1s
    opacity 0
    transform scale(1.5)

    &.show
        transform scale(1)

.mouse-trap
    no-select()
    position fixed
    z-index 999999999
    left 0
    top 0
    width 100%
    height 100%

.bubble-holder
    no-select()
    bubble-spacing = 20px
    pointer-events none

    .text
        pointer-events all

    no-select()
    position fixed
    z-index 22
    opacity 0
    transition opacity .2s
    left 50%
    top 50%
    
    &.show
        opacity 1

    .bubble
        border-radius()
        position absolute
        background color-white
        padding 5px
        color color-black

        code
            padding 2px 5px
            font-size 12px

        &.dir-left, &.dir-right
            transform translateY(-50%)
            top 50%

        &.dir-top, &.dir-bottom
            transform translateX(-50%)
            left 50%

        &.dir-left
            tooltip(left)
            left 100%
            margin-left bubble-spacing

        &.dir-right
            tooltip(right)
            right 100%
            margin-right bubble-spacing

        &.dir-top
            tooltip(top)
            top 100%
            margin-top bubble-spacing

        &.dir-bottom
            tooltip(bottom)
            bottom 100%
            margin-bottom bubble-spacing

        &.bubble-assistant
            background transparent

            button
                padding 10px 20px
                font-size 12px
                margin auto
                display block

            .inner
                bg-image('assistant/standing')
                width 80px
                height 100px
                position relative
                background-size contain
                background-repeat no-repeat
                background-position center center

                .text
                    border-radius()
                    tooltip(left)
                    background color-white
                    position absolute
                    left 100%
                    padding 20px
                    width 180px
                    text-align center
                    transform translateY(-50%)
                    top 50%
                    margin-left 20px

            &:after
                display none

            &.dir-left .inner
                bg-image('assistant/point-left')

            &.dir-right .inner
                bg-image('assistant/point-right')

                .text
                    tooltip(right)
                    background color-white
                    margin-right 10px
                    left auto
                    right 100%

            &.dir-top .inner
                bg-image('assistant/point-up')

            &.dir-bottom .inner
                bg-image('assistant/point-down')