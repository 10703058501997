explode-rotation = rotate3d(1, 0, 1, 60deg)
layers-explode-distance = 150px

.level-editor
    position relative

    .editor-toggles
        toggles-spacing = 13px

        position fixed
        top header-size + toggles-spacing
        z-index 10

        &.left
            left sidebar-size + toggles-spacing

        &.right
            right toggles-spacing

        a
            border-radius()
            display inline-block
            width 45px
            height @width
            line-height @width
            text-align center
            background rgba(darken(color-bg, 10), .8)
            color color-white
            margin-left 10px
            transition background .2s
            position relative

            i
                font-size 24px
                top 7px
                transition background .2s, opacity .2s

            &.disabled
                background rgba(darken(color-bg, 10), .4)

                i
                    color color-bg
                    opacity .6

    .toolbar
        background color-bg
        position fixed
        left 0
        top header-size
        width toolbar-size
        height 100%
        z-index 10
        box-shadow rgba(#000, .1) 2px 0 2px 0

        .tool
            border-radius()
            margin 5px
            width toolbar-size - @margin * 2
            height @width
            line-height @width
            text-align center
            background darken(color-bg, 10)
            color color-white
            transition background .2s
            position relative
            cursor pointer

            i
                line-height @width
                font-size 25px
                opacity .5

            span
                tooltip('left')
                border-radius()
                position absolute
                left 100%
                margin-left 13px
                line-height @width
                background color-tooltip
                padding 0 20px
                opacity 0
                pointer-events none
                transition opacity .2s

            &:hover
                background darken(color-bg, 5)

                span
                    opacity 1

            &.active
                background lighten(color-bg, 10)
                color lighten(color-bg, 30)

    .sidebar
        display block
        position fixed
        left 0
        top header-size
        width sidebar-size
        height 100%
        background color-sidebar
        z-index 4
        box-sizing border-box
        padding 0
        padding-left toolbar-size
        overflow hidden
        box-shadow rgba(#000, .1) 2px 0 2px 0

        .scrollable
            width sidebar-size - toolbar-size + 15px
            padding-right 15px
            box-sizing border-box
            min-height 100%
            max-height 100%
            overflow-y scroll

        .sidebar-panel
            overflow-y auto
            margin-bottom 1px
            width 100%

            h4
                vertical-rhythm()
                no-select()
                smooth-font()
                background darken(color-bg, 10)
                padding 10px 20px
                margin 0
                text-align center
                cursor pointer
                transition background .2s, color .2s
                position relative
                text-transform uppercase
                font-size 14px
                font-weight bold
                color rgba(color-type-body, .8)

                &:after
                    icon()
                    @extends .icon-chevron-right:before
                    transform rotate(-90deg)
                    opacity .3
                    position absolute
                    right 10px
                    top 13px
                    transition transform .2s

                &:hover
                    background darken(color-bg, 12)

            .inner
                padding 20px

            &.collapsed

                .inner
                    display none
                
                h4
                    background darken(color-bg, 15)
                    color rgba(color-type-body, .3)

                    &:after
                        transform rotate(90deg)

        h5
            text-align center
            color mix(color-type-body, color-bg, 50%)

    .map-viewer
        no-select()
        padding-left sidebar-size
        position absolute
        left 0
        perspective 1000px

        .layer
            transition transform 1s, background 1s, opacity .2s

    .status-bar
        border-radius(base-radius 0 0 0)
        position fixed
        bottom 0
        right 0
        font-size 12px
        padding 10px
        background rgba(#000, .9)
        z-index 9

    .editor-overlay
        position fixed
        left sidebar-size
        top 0
        padding-top header-size
        width 100%
        height 100%
        padding-right sidebar-size
        box-sizing border-box
        transition opacity .2s
        opacity 0
        overflow auto
        z-index 3
        pointer-events none

        &.full
            left toolbar-size
            padding-right toolbar-size

        .inner
            background color-bg
            box-sizing border-box
            padding 20px
            padding-right 20px
            min-height 100%

        &.show
            opacity 1
            pointer-events auto

        h2
            background lighten(color-bg, 5)
            text-align center
            padding 20px
            font-size 28px
            border 0
            margin -20px
            vertical-rhythm()
            box-shadow rgba(#000, .1) 0 2px 2px 0
            line-height @font-size

            .mono
                border-radius()
                font-size 18px
                position relative
                background darken(color-bg, 30%)
                padding 7px 15px

    &.exploded .map-viewer

        .layer
            transform explode-rotation
            background rgba(#000, .2)
            border 1px solid rgba(color-white, .1)

            &.layer-0
                transform explode-rotation translateZ(-(layers-explode-distance * 2) + layers-explode-distance / 2)

            &.layer-1
                transform explode-rotation translateZ(-(layers-explode-distance / 2))

            &.layer-2
                transform explode-rotation translateZ(layers-explode-distance / 2)

            &.behaviours, &.entities
                transform explode-rotation translateZ(layers-explode-distance * 2 - layers-explode-distance / 2)