fonts-dir = '../fonts/'

// Bold

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_bold-webfont.eot')
    src url(fonts-dir + 'bariol_bold-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_bold-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_bold-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_bold-webfont.svg#bariol_boldbold') format('svg')
    font-weight bold
    font-style normal

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_bold_italic-webfont.eot')
    src url(fonts-dir + 'bariol_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_bold_italic-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_bold_italic-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_bold_italic-webfont.svg#bariol_boldbold_italic') format('svg')
    font-weight bold
    font-style italic

// Regular

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_regular-webfont.eot')
    src url(fonts-dir + 'bariol_regular-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_regular-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_regular-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_regular-webfont.svg#bariol_regularregular') format('svg')
    font-weight normal
    font-style normal

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_regular_italic-webfont.eot')
    src url(fonts-dir + 'bariol_regular_italic-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_regular_italic-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_regular_italic-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_regular_italic-webfont.svg#bariolregular_italic') format('svg')
    font-weight normal
    font-style italic

// Light

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_light-webfont.eot')
    src url(fonts-dir + 'bariol_light-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_light-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_light-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_light-webfont.svg#bariol_lightlight') format('svg')
    font-weight lighter
    font-style normal

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_light_italic-webfont.eot')
    src url(fonts-dir + 'bariol_light_italic-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_light_italic-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_light_italic-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_light_italic-webfont.svg#bariollight_italic') format('svg')
    font-weight lighter
    font-style italic

// Thin

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_thin-webfont.eot')
    src url(fonts-dir + 'bariol_thin-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_thin-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_thin-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_thin-webfont.svg#bariolthin') format('svg')
    font-weight 100
    font-style normal

@font-face
    font-family 'bariol'
    src url(fonts-dir + 'bariol_thin_italic-webfont.eot')
    src url(fonts-dir + 'bariol_thin_italic-webfont.eot?#iefix') format('embedded-opentype'),
        url(fonts-dir + 'bariol_thin_italic-webfont.woff') format('woff'),
        url(fonts-dir + 'bariol_thin_italic-webfont.ttf') format('truetype'),
        url(fonts-dir + 'bariol_thin_italic-webfont.svg#bariolthin_italic') format('svg')
    font-weight 100
    font-style italic