@font-face {
    font-family: "icons";
    src: url("/icons/icons.eot?26f2ad65ca971ea7b24f6d73f1b3d53e?#iefix") format("embedded-opentype"),
url("/icons/icons.woff?26f2ad65ca971ea7b24f6d73f1b3d53e") format("woff"),
url("/icons/icons.ttf?26f2ad65ca971ea7b24f6d73f1b3d53e") format("truetype"),
url("/icons/icons.svg?26f2ad65ca971ea7b24f6d73f1b3d53e#icons") format("svg");
}

i {
    line-height: 1;
}

i:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.icon-backpack:before {
    content: "\f101";
}
.icon-book:before {
    content: "\f102";
}
.icon-caret-down:before {
    content: "\f103";
}
.icon-caret-left:before {
    content: "\f104";
}
.icon-caret-right:before {
    content: "\f105";
}
.icon-caret-up:before {
    content: "\f106";
}
.icon-chevron-down:before {
    content: "\f107";
}
.icon-chevron-left:before {
    content: "\f108";
}
.icon-chevron-right:before {
    content: "\f109";
}
.icon-chevron-up:before {
    content: "\f10a";
}
.icon-code:before {
    content: "\f10b";
}
.icon-connections:before {
    content: "\f10c";
}
.icon-envelope:before {
    content: "\f10d";
}
.icon-eye-crossed:before {
    content: "\f10e";
}
.icon-eye:before {
    content: "\f10f";
}
.icon-flag:before {
    content: "\f110";
}
.icon-flask:before {
    content: "\f111";
}
.icon-floppy:before {
    content: "\f112";
}
.icon-game:before {
    content: "\f113";
}
.icon-gear:before {
    content: "\f114";
}
.icon-grid-crossed:before {
    content: "\f115";
}
.icon-grid:before {
    content: "\f116";
}
.icon-keys:before {
    content: "\f117";
}
.icon-layers-crossed:before {
    content: "\f118";
}
.icon-layers:before {
    content: "\f119";
}
.icon-learn:before {
    content: "\f11a";
}
.icon-link-crossed:before {
    content: "\f11b";
}
.icon-link:before {
    content: "\f11c";
}
.icon-lock-closed:before {
    content: "\f11d";
}
.icon-lock-open:before {
    content: "\f11e";
}
.icon-map:before {
    content: "\f11f";
}
.icon-minus:before {
    content: "\f120";
}
.icon-paint-bucket:before {
    content: "\f121";
}
.icon-pause:before {
    content: "\f122";
}
.icon-pencil:before {
    content: "\f123";
}
.icon-play:before {
    content: "\f124";
}
.icon-plus:before {
    content: "\f125";
}
.icon-save:before {
    content: "\f126";
}
.icon-target:before {
    content: "\f127";
}
.icon-terminal:before {
    content: "\f128";
}
.icon-tick:before {
    content: "\f129";
}
.icon-tiles:before {
    content: "\f12a";
}
.icon-times:before {
    content: "\f12b";
}
.icon-wand:before {
    content: "\f12c";
}
.icon-world:before {
    content: "\f12d";
}
.icon-zoom-in:before {
    content: "\f12e";
}
.icon-zoom-out:before {
    content: "\f12f";
}
