.mobile-compatibility-alert
    absolute-cover(fixed)
    top header-size
    display none
    z-index 99

    .inner
        position absolute
        left 0
        top 0
        width 100%
        padding 20px
        box-sizing border-box
        background color-warning
        text-align center
        box-shadow rgba(0, 0, 0, .2) 0 2px 2px 0

        &, h3
            color rgba(color-black, .7)

        h3
            font-weight bold
            border-bottom 1px solid rgba(color-black, .1)
            margin 0 -20px
            margin-bottom 20px
            padding-bottom 20px

    &.hide
        display none

    .overlay
        absolute-cover()
        background rgba(0, 0, 0, .7)

    @media (max-width: mobile-breakpoint)
        display block