.modal-learn-alert

    img
        position absolute
        bottom 100%
        margin-bottom -30px
        width 200px
        left 50%
        transform translateX(-50%)

    label
        color rgba(color-white, .5)