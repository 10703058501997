.assistant
    padding-bottom 60%
    background-size contain
    background-repeat no-repeat
    background-position center center

    &.assistant-standing
        bg-image('assistant/standing')

    &.assistant-palette
        bg-image('assistant/palette')