.modal-save-slots
    overflow hidden

    ul.items-list
        font-size 15px

        li
            transition background .2s
            padding 0
            position relative

            a.inner
                padding 15px
                display block
                color color-white

            a.clear
                margin-left -10px
                color color-danger
                position absolute
                right 30px
                top 50%
                transform translateY(-50%)
                z-index 2

        li:hover
            background darken(color-bg, 10)

        li:first-child
            margin-top 0

        a.clear
            position relative
            top 2px

        strong
            font-weight normal

        em
            opacity .5