easing-back-in = cubic-bezier(0.875, 0, 0.425, 0.985)

@keyframes pop-in {

    0% {
        transform scale(1.4)
        opacity 0
    }
    
    100% {
        opacity 1
        transform scale(1)
    }

}

@keyframes modal-open {

    0% {
        transform translate3d(-50%, -50%, 200px)
        opacity 0
    }
    
    100% {
        opacity 1
        transform translate3d(-50%, -50%, 0)
    }

}

@keyframes char-pop-vanish {

    0% {
        transform scale(1)
    }

    50% {
        transform scale(1.2)
    }
    
    100% {
        transform scale(0)
    }

}

@keyframes char-jumps {

    0% {
        margin-top 0
    }

    50% {
        margin-top -10px
    }
    
    100% {
        margin-top 0
    }

}

@keyframes char-shake {

    0% {
        margin-left 0
    }

    25% {
        margin-left -5px
    }

    50% {
        margin-left 0
    }

    75% {
        margin-left 5px
    }
    
    100% {
        margin-left 0
    }

}

@keyframes char-damage {

    0% {
        margin-left 0
    }

    25% {
        margin-left -5px
    }

    50% {
        margin-left 0
    }

    75% {
        margin-left 5px
    }
    
    100% {
        margin-left 0
    }

}

@keyframes fade-in {

    0% {
        opacity 0
    }

    100% {
        opacity 1
    }
}

@keyframes fade-30 {

    0% {
        opacity 0
    }

    100% {
        opacity .3
    }
}

@keyframes prompt-glow {

    0% {
        box-shadow rgba(color-white, 0) 0 0 0 3px
    }

    100% {
        box-shadow rgba(color-white, .3) 0 0 0 6px
    }
}

@keyframes float {

    0% {
        transform translateY(0px)
    }

    100% {
        transform translateY(20px)
    }
}

@keyframes element-highlight-text {

    0% {
        color #f16844
    }

    100% {
        color #f6b09e
    }

}

@keyframes fade-out {

    0% {
        opacity 1
    }

    100% {
        opacity 0
    }

}

@keyframes game-tab-glow {

    0% {
        background lighten(color-bg, 15%)
    }

    100% {
        background color-primary
    }

}

@keyframes cloud {

    0% {
        left -300px
    }

    100% {
        left 100%
    }

}

@keyframes glow-text-color {

    0% {
        color rgba(color-white, 1)
    }

    100% {
        color rgba(color-white, .6)
    }

}