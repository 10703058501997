.modal.modal-pictures-gallery
    width 100%
    height 100%
    left 0
    top 0
    z-index 16
    background transparent
    transform none
    transition none

    .close-overlay
        absolute-cover()

    img
        border-radius()
        no-select()
        position absolute
        max-width 80%
        max-height 80%
        width auto
        height auto
        left 50%
        top 50%
        transform translate3d(-50%, -50%, 0)
        cursor pointer

    .pagination-left, .pagination-right
        position absolute
        top 0
        height 100%
        background black
        width 10%
        font-size 30px
        color color-white
        opacity .5
        transition opacity .2s

        &:hover
            opacity 1

        i
            position absolute
            left 50%
            top 50%
            transform translate(-50%, -50%)

    .pagination-left
        left 0
        
    .pagination-right
        right 0

    .page
        border-radius()
        position absolute
        bottom 10%
        margin-bottom -20px
        left 50%
        background black
        transform translateX(-50%)
        display inline-block
        padding 10px 20px

    a.close
        border-radius()
        position absolute
        right 10px
        top 10px
        line-height 25px
        width @line-height
        text-align center
        background color-bg
        color color-white
        font-size 20px
        font-weight bold