.game-view

    .character-sprite
        sharp-scale()
        position absolute
        z-index 3
        width (100% / map-size[0])
        padding-bottom @width
        transition left .2s, top .2s
        background-size cover

        &.pose-up
            background-image: url('/assets/sprites/hero-up.png')

        &.pose-right
            background-image: url('/assets/sprites/hero-right.png')

        &.pose-down
            background-image: url('/assets/sprites/hero-down.png')

        &.pose-left
            background-image: url('/assets/sprites/hero-left.png')

        .speech-bubble
            border-radius()
            tooltip('bottom', 5px)
            font-size 13px
            background rgba(color-white, .8)
            padding 8px 15px
            color color-black
            position absolute
            bottom 100%
            left 50%
            transform translateX(-50%)
            opacity 0
            margin-bottom 0
            transition opacity .2s, margin-bottom .2s

            &.show
                opacity 1
                margin-bottom 10px

            &.shout
                font-weight bold

        &.anim-pop-vanish
            animation char-pop-vanish 1s easing-back-in

        &.anim-jumps
            animation char-jumps .5s 2 easing-back-in

        &.anim-shake
            animation char-shake .5s 2

        &.anim-damage
            animation char-damage .5s 2

            &:after
                absolute-cover()
                background-image: url('/assets/sprites/damage.png')
                background-size cover
                transform scale(1.3)
                content ''
                animation fade-30 alternate .2s infinite

    &.transitioning .character-sprite
        transition none