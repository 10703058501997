code-gutter-size = 50px

.code-editor
    margin-bottom 15px
    
    &:last-child
        margin-bottom 0

    .heading
        border-radius(base-radius base-radius 0 0)
        background color-code-bg
        padding 10px
        text-align center
        color color-grey-light

    textarea, .syntax-highlight
        smooth-font()
        padding 20px
        width 100%
        border 0
        margin 0
        white-space pre
        display block
        text-align left

    textarea, .syntax-highlight pre, .gutter
        font-size 13px
        font-family font-code

    .code-editor-inner
        position relative

        &:before
            position absolute
            content ''
            left 0
            top 0
            width code-gutter-size
            height 100%
            background rgba(#000, .2)

        textarea, .syntax-highlight pre
            padding 10px
            padding-left code-gutter-size + @padding
            box-sizing border-box

    textarea
        border-radius()
        background color-black
        box-sizing border-box
        min-height 300px
        color lighten(color-bg, 50%)
        text-fill-color transparent
        resize vertical

    &.has-title textarea
        border-radius(0 0 base-radius base-radius)

    .syntax-highlight
        absolute-cover()
        color color-white
        position absolute
        left 0
        top 0
        pointer-events none
        margin 0
        width 100%
        height 100%
        overflow hidden
        padding 0

        .gutter
            position absolute
            width code-gutter-size
            left 0
            top 0
            text-align right
            padding 10px 0
            box-sizing border-box
            z-index 1
            color rgba(color-white, .2)

            .line
                padding 0 10px
                ellipsis()

                &.active
                    background rgba(color-white, .08)

        pre
            absolute-cover()
            margin 0

        // Tomorrow Night Eighties Theme
        // Original theme - https://github.com/chriskempson/tomorrow-theme
        // http://jmblog.github.com/color-themes-for-google-code-highlightjs

        .token-comment
        .title
          color #999999

        .token-variable
        .token-attribute
        .token-tag
        .token-regexp
        &.ruby .token-constant
        .token-xml .token-tag .token-title
        .token-xml .token-pi
        .token-xml .token-doctype
        .token-html .token-doctype
        .token-css .token-id
        .token-css .token-class
        .token-css .token-pseudo
            color #f2777a

        .token-number
        .token-preprocessor
        .token-built_in
        .token-literal
        .token-params
        .token-constant
            color #f99157

        .token-class
        &.ruby .token-class .token-title
        &.css .token-rules .token-attribute
            color #ffcc66

        .token-string
        .token-value
        .token-inheritance
        .token-header
        .token-ruby .token-symbol
        .token-xml .token-cdata
            color #99cc99

        &.css .token-hexcolor
            color #66cccc

        .token-function
        &.python .token-decorator
        &.python .token-title
        &.ruby .token-function .token-title
        &.ruby .token-title .token-keyword
        &.perl .token-sub
        &.javascript .token-title
        &.coffeescript .token-title
            color #6699cc

        .token-keyword
        &.javascript .token-function
            color #cc99cc