html, body
    margin 0
    height 100%

body
    background color-bg

    @media (max-width: mobile-breakpoint)
        padding-top header-size

// Loading state
body

    &:before, &:after
        opacity 0
        transition opacity 1s
        pointer-events none

    &:before
        absolute-cover(fixed)
        content ''
        background-color darken(color-bg, 50%)
        background-image radial-gradient(600px, rgba(color-white, .15), rgba(color-white, 0))
        background-position center center
        background-repeat no-repeat
        z-index 999

    &:after
        bg-image('ui/judoka-face')
        content 'Loading...'
        width 100px
        height @width
        background-size @width @width
        background-repeat no-repeat
        background-position center top
        text-align center
        position fixed
        left 50%
        top 50%
        transform translate(-50%, -50%)
        z-index 9991
        padding-top 110px
        text-transform uppercase
        color rgba(color-white, .8)
        animation glow-text-color .5s infinite alternate

body.loading

    &:before, &:after
        opacity 1

header
    clearfix()
    position relative
    z-index 13
    background darken(color-bg, 10)
    padding 10px
    height header-size - (@padding * 2)
    box-shadow rgba(#000, .2) 0 2px 2px 0

    .toggle
        display none

    a
        display block
        color color-white

    .left a
        float left
        margin-right 10px

    .right a
        float left
        margin-left 10px

    .logo
        illustration-fixed('ui/logo-header')
        float left

    ul.nav
        debullet()
        float left
        margin-left 30px

        li
            display inline-block

            a
                display inline-block
                padding 10px 10px
                opacity .4
                transition opacity .2s

                &:hover
                    opacity .8

                &.active
                    opacity 1

            i
                margin-right 5px
                opacity .4
                top 2px

    ul.user-nav
        debullet()

        li
            display inline-block

        .info-card
            margin-bottom -5px

    .avatar
        border-radius(100%)
        display inline-block
        width header-size - 20px
        height @width
        background-size @width + 2px @height + 2px
        background-position center center
        box-shadow rgba(0, 0, 0, .15) 0 2px 2px 0

    .username
        display inline-block
        position relative
        top -13px
        margin 0 15px

    @media (max-width: mobile-breakpoint)
        text-align center
        position fixed
        left 0
        top 0
        width 100%
        height header-size
        box-sizing border-box

        .inner
            display none
            position fixed
            left 0
            top header-size
            width 100%
            background darken(color-bg, 15)

            ul
                margin 0

                li
                    display block
                    line-height 40px
                    border-bottom 1px solid rgba(color-white, .1)

                    &:last-child
                        border-bottom 0

            a.info-card
                display block
                margin 10px 0 -3px

            a.button
                border-radius(0)
                margin -1px 0
                display block

        &.open

            .toggle .hamburger
                hamburger-open(.34)

            .inner
                display block

        .toggle
            border-radius()
            position absolute
            right 5px
            top 5px
            width (header-size - 10px)
            height @width
            background color-bg
            display block

            .hamburger
                hamburger-closed(.34)
                transform translate(-50%, -50%)
                position absolute
                left 50%
                top 50%

        .logo
            display block

        .left, .right
            float none

            a, .nav
                float none

.row
    grid-system(12, 20px, '.col', '.off', true, '-', mobile-breakpoint)

.page-width
    page-width()
    position relative

section
    display block
    padding 30px 0
    
    &:last-child
        margin-bottom 0

.center
    text-align center

.left
    float left

.right
    float right

.hidden
    display none

.capped
    max-width: 350px
    margin auto

#view
    transition background .2s

footer
    background color-black
    padding 10px
    line-height 40px

    a
        color color-white
 
    ul
        text-align right
        debullet()

        li
            display inline-block
            margin 0 10px

            &:first-child
                margin-left 0

            &:last-child
                margin-right 0

            img.logo
                margin -12px 0

    @media (max-width: mobile-breakpoint)
        
        ul
            text-align center