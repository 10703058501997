.level-editor .item-editor
    text-align center

    ul.states-list
        debullet()
        margin -10px -10px 10px

        li
            border-radius()
            display inline-block
            background darken(color-bg, 10)
            width (100% / 3)
            margin 10px
            box-sizing border-box
            position relative

        .remove
            border-radius(100%)
            background color-danger
            position absolute
            top 100%
            margin-top -15px
            left 50%
            transform translateX(-50%)
            width 25px
            line-height @width
            text-align center
            z-index 1
            color color-white
            font-weight bold
            opacity 0
            transition opacity .2s

            &:after
                content '\d7'
                position relative
                top -2px

        &:hover .remove
            opacity 1

        h5
            border-radius(base-radius base-radius 0 0)
            ellipsis()
            font-family font-code
            font-size 12px
            margin-bottom 10px
            width 100%
            background darken(color-bg, 15)
            line-height 30px

        h6
            margin-bottom 5px
            font-size 13px