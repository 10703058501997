#overlay
    absolute-cover(fixed)
    background rgba(#000, .7)
    z-index 15
    opacity 0
    pointer-events none
    transition opacity .2s

body.modal-open
    overflow hidden
    perspective 1000px

    #overlay
        opacity 1
        pointer-events none

.modal
    center-absolute()
    border-radius()
    position fixed
    background color-bg
    z-index 16
    width: 400px
    text-align center
    animation modal-open .2s
    transition opacity .2s

    ul.modal-tabs
        border-radius(base-radius base-radius 0 0)
        debullet()
        clearfix()
        overflow hidden
        margin-bottom 0

        li
            float left
            width 50%
            padding 10px
            background darken(color-bg, 10)
            box-sizing border-box
            text-align center
            color rgba(color-white, .3)
            cursor: pointer
            transition background .2s, color .2s

            &:hover:not(.active)
                background darken(color-bg, 5)
                color color-white

            &.active
                color color-white
                background transparent

    &.hide
        opacity 0 
        pointer-events none 

    .modal-actions, .modal-body, .modal-title
        padding 20px

        i
            opacity .5
            margin-right 5px

    .modal-title
        border-bottom 1px solid rgba(color-ruler, .1)

    .modal-actions
        border-top 1px solid rgba(color-ruler, .1)

        .button, button, input
            margin-bottom 0

    &.closing
        opacity 0