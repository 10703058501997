ul.levels-list
    debullet()
    margin -5px -10px
    margin-bottom 15px

    li
        width (100% / 4)
        display inline-block
        padding 10px
        box-sizing border-box
        position relative

        &:hover .inner img
            opacity .3

        &.locked
            filter grayscale()
            opacity .3

            a
                cursor default

            &:hover .inner img
                opacity 1


        .inner
            border-radius()
            ellipsis()
            color color-type-body
            display inline-block
            background rgba(color-black)
            width 100%
            transition background .2s
            position relative
            box-shadow rgba(color-black, .2) 0 3px 0 0
            transition background .2s

        img
            border-radius()
            width 100%
            display block
            transition opacity .2s

        .name
            border-radius()
            display inline-block
            padding 5px 20px
            box-sizing border-box
            position absolute
            left 50%
            bottom 0
            transform translateX(-50%)
            background rgba(color-black, .9)
            margin-bottom 5px
            z-index 1

        &.new .inner
            padding-top 100%
            background mix(color-bg, color-success, 30%)

            &:after
                icon()
                @extends .icon-plus:before
                position absolute
                left 50%
                top 50%
                transform translate(-50%, -50%)
                color color-white
                font-size 100px

        &.new:hover .inner
            background color-success

        .type-icon
            circle(20px, color-white)
            display: inline-block
            margin -5px 7px -5px 0
            color color-bg
            font-size 12px

        .actions
            position absolute
            left 50%
            top 50%
            width 70%
            transform translate(-50%, -50%)
            opacity 0
            transition opacity .2s

        .button
            border-radius(0)
            box-shadow rgba(0, 0, 0, .1) 0 2px 0 0
            display block
            margin 0

            &:first-child
                border-radius(base-radius base-radius 0 0)

            &:last-child
                border-radius(0 0 base-radius base-radius)

        &:hover .actions
            opacity 1