.pictures-showcase
    spacing = 10px

    img
        border-radius()
        box-shadow color-black 0 0 2px 1px
        width 100%

    a.main
        border-radius()
        clearfix()
        display block
        margin-bottom spacing * 2
        background black
        position relative
        overflow hidden
        
        img
            float left
            transition opacity .2s

        i
            icon()
            @extends .icon-zoom-in:before
            position absolute
            color color-white
            left 50%
            top 50%
            transform translate(-50%, -50%)
            font-size 80px
            opacity 0
            transition opacity .2s

        &:hover

            img
                opacity .3

            i
                opacity 1
                    

    ul
        debullet()
        margin -(spacing)

        li
            float left
            padding (spacing / 3) (spacing / 2)
            width (100% / 3)
            cursor pointer
            opacity .5
            transition opacity .2s
            box-sizing border-box

            img
                border 1px solid rgba(color-primary, 0)
                box-sizing border-box
                transition border .2s
            
            &:hover
                opacity .8

            &.active
                opacity 1
                
                img
                    border-color rgba(color-primary, .5)