.tile
    sharp-scale()
    ellipsis()
    position relative
    display inline-block
    float left
    background-size cover
    color darken(color-bg, 20)
    font-family font-code
    font-size 9px
    text-align center

    &:after
        absolute-cover()
        content ''
        z-index 1
        box-sizing border-box
        border border-grid

    img, &:after
        absolute-cover()

    span
        white-space pre-wrap
        left 0
        top 50%
        position absolute
        transform translateY(-50%)
        width 100%
        max-height editor-tile-size
        overflow hidden
        padding 3px
        box-sizing border-box

    &.labelled, &.clear
        background rgba(color-white, .1)

    &.selected
        opacity 1

    &.clear
        line-height editor-tile-size

        &:after
            icon()
            @extends .icon-times:before
            font-size 25px