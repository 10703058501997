.game-view
    absolute-cover(fixed)
    no-select()
    width 100%
    background color-black

    .loading
        border-radius()
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        padding 10px 20px
        background color-bg

    .save-feedback
        border-radius()
        opacity 0
        transition opacity .2s
        position fixed
        left 50%
        top 50%
        transform translate(-50%, -50%)
        background rgba(color-black, .8)
        padding 10px 15px
        z-index 9
        pointer-events none

        &.show
            opacity 1

    &.game-over .prompt-wrap
        opacity .3

    .map-wrap
        no-select()
        position absolute
        right 0
        top 50%
        transform translateY(-50%)
        margin-top 
        z-index 2
        margin-top (header-size / 2 - game-stats-size / 2 - 1px)

    .map-viewer, .map
        absolute-cover()

    .tile:after
        opacity .3

    .panel
        background color-bg
        position absolute
        left 0
        top header-size
        box-sizing border-box
        padding-bottom game-tabs-size

        h2
            smooth-font()
            font-size 25px
            background darken(color-bg, 20%)
            padding 10px
            text-align center
            font-weight bold

            i
                position relative
                top 2px
                opacity .5
                margin-right 10px

        .panel-heading
            font-size 25px
            font-weight normal
            background darken(color-bg, 8)
            line-height 60px
            text-align center
            margin-bottom 20px

    ul.game-tabs
        debullet()
        position absolute
        left 20px
        bottom 20px

        li
            border-radius()
            width game-tabs-size
            height @width
            line-height @width
            float left
            margin-right 10px
            background lighten(color-bg, 10%)
            text-align center
            color rgba(color-white, .5)
            font-size 28px
            cursor pointer
            transition background .2s
            position relative

            i
                top 4px

            .tooltip-hover
                font-size 11px
                width auto
                padding 10px 20px
                line-height 20px
                white-space nowrap

            .count
                circle(20px, darken(color-primary, 10))
                position absolute
                right -6px
                top -6px
                font-size 11px
                color color-white
                font-weight bold
                transition opacity .2s

                &.hide
                    opacity 0
                    pointer-events none

            &.highlight
                animation game-tab-glow .5s 2 alternate
                transition none

            &:hover
                background lighten(color-bg, 15%)

            &.active
                background lighten(color-bg, 30%)