.modal-explain
    width 500px

    img
        width 100%
        margin-bottom 20px

    .modal-title h4
        font-size 25px

    .modal-body
        font-size 17px

    .assistant
        padding-bottom 40%
        margin auto
        margin-bottom 20px