.game-view

    .game-overlay
        absolute-cover()
        content ''
        opacity 0
        pointer-events none
        padding-bottom game-stats-size * 2
        transition opacity .2s
        z-index 9
        background rgba(color-black, .6)
        position relative

        .inner
            border-radius()
            position absolute
            width 50%
            left 50%
            top 50%
            margin-left: -(@width / 2)
            margin-top -70px
            text-align center
            background color-black
            padding 30px
            z-index 10

            &:after
                background-size contain
                background-repeat no-repeat
                background-position center center
                content ''
                position absolute
                left 50%
                transform translateX(-50%)
                top -80px
                width 100%
                height 100px

            h3
                font-weight normal
                margin-bottom 10px

        &.show
            opacity 1
            pointer-events auto

            .inner
                animation pop-in 1s

        &.success
            background rgba(color-green, .4)

            .inner:after
                bg-image('illustrations/judoka-win')
                margin-left -20px

        &.failure
            background rgba(color-red, .4)

            .inner:after
                bg-image('illustrations/judoka-lose')