.game-view .prompt-wrap
    position absolute
    bottom game-tabs-size + 20px
    padding 20px
    width 100%
    box-sizing border-box
    line-height 25px
    font-size 12px
    font-family font-code

    button.submit
        position absolute
        top 25px
        right 15px
        width 35px
        line-height @width
        background lighten(color-bg, 3)
        z-index 1
        color color-white
        padding 0

        &:hover
            background lighten(color-bg, 6)

    &.blurred:not(.disabled):after
        opacity 1

    &:after
        position absolute
        left 0
        top 10px
        width 100%
        height 60px
        content 'Click here to write a spell'
        font-style italic
        font-weight bold
        text-align center
        transition opacity .2s, color .2s, background .2s
        line-height @height
        font-family font-body
        font-size 17px
        background rgba(color-bg, .8)
        color rgba(color-white, .8)
        cursor pointer
        opacity 0
        z-index 2

    &:hover:after
        color rgba(color-white, 0)
        background rgba(color-bg, .2)
        pointer-events none
            

    &.prompt-mode

        input
            animation prompt-glow 1s infinite alternate

        &:before
            content ':'

    &:before
        content '>'
        position absolute
        left 40px
        margin-top 9px
        opacity .3

    input.prompt
        padding-left 40px
        line-height @line-height
        font-size @font-size
        font-family @font-family
        transition opacity .2s, box-shadow .2s
        opacity .8
        box-shadow rgba(color-primary, 0) 0 0 0 2px

        &:focus
            box-shadow rgba(color-primary, .7) 0 0 0 2px

        &[disabled]
            opacity .3

.terminal
    smooth-font()
    font-family font-code
    padding 20px
    box-sizing border-box
    font-size 12px
    position absolute
    overflow hidden
    left 0
    top 60px
    width 100%

    &:after
        content ''
        position absolute
        left 0
        top 0
        width 100%
        height 50px
        background linear-gradient(color-bg, rgba(color-bg, 0))
        cursor-events none

    .inner-wrap
        margin -20px -13px
        padding 20px 0
        height 100%
        position relative

    .inner
        position absolute
        left 0
        bottom 0
        width 100%
        max-height 100%
        padding 0 15px
        overflow scroll
        overflow-x hidden

    .logs
        width 100%

    .log
        word-wrap break-word
        position relative

        &:after
            content ''
            position absolute
            width 10px
            height 100%
            left -15px
            top 0
            background lighten(color-bg, 70%)
            opacity 0
            animation fade-out 3s 1

        &.log-error
            color lighten(color-red, 5)
            font-weight bold

        &.log-command, &.log-answer
            font-style italic
            font-weight 100

            span
                opacity .2

        &.log-command:before
            content '$'
            opacity .2
            margin-right 5px
            font-weight bold

        &.log-answer:before
            content '> '

        &.log-ascii
            font-weight bold
            color #e7ea87
            word-wrap normal
            width 9999px
            white-space pre
            font-size 9px

        &.log-warning
            color #e5d127

        &.log-health-lose, &.log-mana-use, &.log-health-gain, &.log-mana-charge
            font-style italic

        &.log-health-lose

            &, .token
                color color-red

        &.log-health-gain

            &, .token
                color color-green

        &.log-mana-charge

            &, .token
                color saturate(mix(color-green, color-blue, 20), 15)

        &.log-mana-use

            &, .token
                color saturate(mix(color-red, color-blue, 60), 40)

        &.log-sequence
            font-style italic
            color color-grey-lighter

        &.log-description
            font-style italic
            color mix(color-grey-lighter, color-blue)

    .token

        &.token-password
            color #f794d8
            font-weight bold

        &.token-person
            color #efc05c

        &.token-location
            color #55f8bc

        &.token-cmd
            color #76dffe

        &.token-action
            color #f0d7ce
            font-style italic

        &.token-speech
            font-style italic
            font-weight bold
            opacity .7

            &:before, &:after
                content '"'

        &.token-options
            color #f0dfb8

        &.token-element
            color #f16844
            font-weight bold
            animation element-highlight-text 1s infinite alternate

        &.token-arg
            color color-grey-light
            opacity .8

        &.token-item
            color #d8e63b
            font-weight normal