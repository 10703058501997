body
    font-family font-body
    color color-type-body

h1, h2, h3, h4, h5, h6
    font-family font-heading
    font-weight normal
    color color-type-heading

    &.center
        text-align center

    &.ruled
        border-bottom 1px solid color-ruler
        padding-bottom 10px

i
    icon()
    position relative

h1
    font-size 40px

h2
    font-size 30px

h3
    font-size 25px
    font-weight bold

    &.page-title
        margin-bottom 25px
        text-align center

        i
            opacity .5
            margin-right 10px
            top 2px

h4
    font-size 20px
    font-weight bold

h5
    font-size 18px
    font-weight bold

h6
    font-size 15px

*:focus
    outline 0

a
    color color-type-link
    text-decoration none
    cursor pointer

    img
        border 0

h1, h2, h3, h4, h5, h6, p, ul, ol, input, button, textarea, code, hr, .box
    margin-top 0
    margin-bottom 20px

    &:last-child
        margin-bottom 0

h1, h2, h3, h4
    font-weight 100

h5, h6
    font-weight normal

ul
    list-style square
    padding-left 20px

code
    padding 10px
    border-radius()
    background rgba(#000, .1)
    color darken(color-primary, 50%)
    font-size 15px

pre code
    display block

hr
    background color-ruler
    border 0
    height 1px

.mono
    font-family font-code

.text-danger, .text-error
    color color-danger

.text-primary
    color color-primary

.text-success
    color color-success

p.large
    font-size 19px