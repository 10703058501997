.modal-save-level
    preview-size = 200px

    .preview-loading
        border-radius()
        opacity .5
        width preview-size
        height @width
        background rgba(color-black, .5)
        line-height preview-size
        margin auto

    .preview-choice
        position relative
        width preview-size
        height preview-size
        margin auto

        img
            border-radius()
            background-color darken(color-bg, 20%)
            width 100%
            margin auto

        .count
            border-radius()
            font-size 12px
            padding 5px 10px
            background: color-black
            display inline-block
            position absolute
            left 50%
            bottom -8px
            transform translateX(-50%)
            opacity .8

        a.page
            border-radius(100%)
            width 30px
            height @width
            line-height @width
            text-align center
            position absolute
            top 50%
            transform translateY(-50%)
            font-size 20px
            color rgba(color-white, .3)
            background rgba(mix(color-black, color-bg), .9)

            &:after
                icon()

            &.page-prev
                left -(@width / 2)

                &:after
                    @extends .icon-chevron-left:before

            &.page-next
                right -(@width / 2)

                &:after
                    @extends .icon-chevron-right:before