input[type='text']
input[type='password']
input[type='email']
input[type='number']
input[type='tel'],
input[type='date']
textarea, select
    border-radius()
    smooth-font()
    vertical-rhythm()
    background darken(color-bg, 10)
    border 0
    padding 10px
    box-sizing border-box
    color color-type-body
    font-weight normal

    &.block
        display block
        width 100%

    &.large
        line-height 25px
        font-size 14px
        padding 10px 20px

select
    vertical-rhythm()
    bg-image('ui/select-arr', 10px 10px, right 10px center, no-repeat)
    appearance none
    position relative
    cursor pointer

    &:hover
        background-color darken(color-bg, 50%)
        transition background .2s

    &.block
        display block
        width 100%

.split-field
    clearfix()
    vertical-rhythm(2px)

    label
        width 50%
        padding-right 15px
        box-sizing border-box
        float left
        text-align right
        padding-top 4px
        opacity .6

    .field
        width 50%
        float right
        text-align left

        input[type='checkbox']
            position relative
            top 4px

label input[type='checkbox']
    margin-right 10px