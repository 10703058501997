.key-value-editor
    margin auto
    vertical-rhythm()

    ul
        debullet()
        border-radius()
        background darken(color-bg, 15%)
        padding 1px
        margin-bottom 10px

    li
        clearfix()
        margin-top 1px
        position relative

        &:first-child
            margin-top 0

        &:hover i
            opacity 1

    .key, .value
        line-height 20px
        box-sizing border-box

    input, select
        margin-bottom 0

    .key
        smooth-font()
        padding 6px 10px
        font-family font-code
        font-size 11px
        margin-bottom 0
        width 50%
        text-align right
        float left

    i
        color color-danger
        position absolute
        padding 8px 10px
        left 0
        top 0
        opacity 0
        transition opacity .2s

    .value
        width 50%
        float right

    .placeholder
        font-style italic
        padding 10px
        text-align center
        opacity .5

    .add-key

        .key
            width 70%
            padding 0

        .value
            width 30%

        input
            border-radius(base-radius 0 0 base-radius)
            text-align center

        button
            border-radius(0 base-radius base-radius 0)
            padding 8px