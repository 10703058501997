ul.inventory-list
    debullet()
    box-sizing border-box
    padding 0 10px 0 20px
    overflow auto

    li
        border-radius()
        position relative
        width 60px
        float left
        background darken(color-bg, 10)
        margin-bottom 10px
        margin-right 10px
        cursor pointer

        &.empty
            font-style italic
            background none
            width auto
            text-align center
            float none
            opacity .5
            font-size 18px
            margin-top 30px
            cursor default

        .tile
            width 100%

        .count
            circle(20px, color-white)
            position absolute
            color darken(color-bg, 10)
            right -5px
            bottom -5px
            font-size 11px
            font-weight bold

        .tooltip-hover
            font-size 12px
            text-align center
            white-space: nowrap;