.view-share
    padding 30px 0

    .share-item
        border-radius()
        max-width 400px
        background darken(color-bg, 10)
        overflow hidden
        margin auto

        img.cover
            width 100%

        h2
            margin-bottom 5px
            font-size 24px
            margin-top -5px

        p
            margin-bottom 5px

        h4
            font-size 14px
            margin-bottom 20px

        .detail
            padding 15px
            text-align center

        .buttons
            text-transform uppercase

            .button
                margin-bottom 0