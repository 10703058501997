.game-view .overlay
    background rgba(color-black, .8)
    position fixed
    width 100%
    height 100%
    left 0
    top 0
    z-index 9
    opacity 0
    pointer-events none
    transition opacity .2s

    &.show
        opacity 1
        pointer-events auto

    a.close
        border-radius()
        position absolute
        top 15px
        right 15px
        width 40px
        line-height @width
        text-align center
        color color-white
        background color-bg
        cursor pointer
        font-size 20px
        transition background .2s

        &:after
            icon()
            @extends .icon-times:before

        &:hover
            background lighten(color-bg, 5)

    .game-help
        border-radius()
        max-width 500px
        width 90%
        background color-bg
        position absolute
        left 50%
        top 50%
        transform translate(-50%, -50%)
        padding 20px
        
        h3
            border-radius(3px 3px 0 0)
            text-align center
            margin -20px
            margin-bottom 20px
            background darken(color-bg, 10)
            padding 20px

        ul.items-list
            margin-bottom 20px

        h4
            text-align center
            opacity .6

        .commands-list
            max-height 350px
            overflow auto
            margin 0 -20px -20px
            padding 0 20px 20px

        .command
            border-radius()
            margin-bottom 10px
            background darken(color-bg, 5)
            padding 20px

            .example, .command-parts
                smooth-font()
                font-family font-code

            .example

                &:before
                    smooth-font(false)
                    content 'Example usage: '
                    font-family font-body
                    opacity .5

            .explain
                margin-bottom 0
                font-style italic
                opacity .8

            .command-parts

                .call
                    color #ffcc66

                    &:after
                        content '('

                .call, >span

                    &:after
                        color rgba(color-white, .5)

                > span:after
                    content ', '

                > span:last-child:after
                    content ')'

                > span:first-child:last-child:after
                    content '()'

                .tag
                    position relative

                    &.argument
                        cursor pointer

                    .tooltip-hover
                        smooth-font(false)
                        font-size 12px
                        font-family font-body

                    &.number
                        color #f99157

                    &.string
                        color #99cc99

            ul.explain-parts
                color rgba(color-white, .2)

                strong
                    font-weight normal
                    color rgba(color-white, 1)
                    
                em
                    opacity .5
                    color rgba(color-white, .8)