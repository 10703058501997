.level-editor .map-editor

    .code-editor textarea
        min-height 200px

    .map-preview img
        border-radius()
        sharp-scale()
        width 100%

    .font-preview
        max-width 100%
        overflow hidden
        font-size 7px
        position relative
        
        &:after
            content ''
            width 30px
            height 100%
            background-image linear-gradient(left, transparent, color-sidebar)
            position absolute
            right 0
            top 0