.highlight-pointer
    bg-image('ui/pointer-arrow', cover, no-repeat, center center)
    position absolute
    top 100%
    left 50%
    margin-top 20px
    width 94px
    height 128px
    margin-left -(@width / 2)
    z-index 999
    animation float infinite alternate 1s
    opacity 0
    transition opacity .2s

    &.show
        opacity 1