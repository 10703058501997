stat-color(base-color)
    background desaturate(darken(base-color, 30), 30)

    .bar
        background base-color

.game-view

    .stats
        position absolute
        top 100%
        width 100%
        z-index 5

        .stat
            height (game-stats-size / 2)
            background darken(color-black, 10)
            overflow hidden

            .bar
                height 100%
                transition width .2s
                margin-top 1px

            &.stat-mana
                stat-color(color-blue)

            &.stat-health
                stat-color(color-red)