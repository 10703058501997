.map-viewer
    no-select()
    background #222
    width 100%
    height 100%
    box-sizing border-box
    overflow hidden

    .map
        left 50%
        top 50%
        margin-left -( editor-tile-size * map-size[0] / 2 )
        margin-top @margin-left

    &.map-viewer-edit

        .toggles
            display none

        .tile-anchor
            pointer-events none

        .tile-anchor
            position absolute

        .layer.grid
            // opacity .1
            opacity 0

    .assisted-drawing-highlighter
        pointer-events none
        opacity 0
        transition opacity 1s

        &, canvas
            absolute-cover()

        canvas.mask
            opacity .1

        canvas.lines
            animation fade-out 1s infinite alternate

        &.show
            opacity 1

.map
    position relative
    width editor-tile-size * map-size[0]
    height editor-tile-size * map-size[1]

    .toggles
        position absolute
        right 5px
        top 5px
        z-index 9

        a
            border-radius()
            display inline-block
            width 35px
            height @width
            line-height @width
            background color-bg
            text-align center
            color color-white
            box-shadow 0 2px 3px 0 rgba(#000, .2)
            transition background .2s

            i
                opacity .5
                transition opacity .2s
                top 3px

            &.active
                background lighten(color-bg, 10)

                i
                    opacity .2

    .layer
        width 100%
        height 100%
        position absolute
        left 0
        top 0
        transition opacity .2s, background .3s

        &.hide
            opacity 0

        &.layer-2
            z-index 4

        &.layer-3
            z-index 5

        &.grid
            opacity 0
            z-index 6

        &.entities, &.behaviours
            opacity .3

        &.entities, &.behaviours, &.items, &.instances, &.items
            z-index 5

            .tile
                position absolute
                transition left .2s, top .2s

        &.instances, &.items
            z-index 5

        &.items

            .tile
                background-color: rgba(color-white, .15)

                &:after
                    border 1px dashed rgba(color-black, .1)

    &.show-grid .grid
        opacity .3

    .tile
        width (100% / map-size[0])
        padding-bottom (100% / map-size[1])
        
    canvas.tile
        padding-bottom 0

&.mode-entities .layer.entities, &.mode-behaviours .layer.behaviours, &.mode-items .layer.items
    opacity 1
    background rgba(color-white, .2)