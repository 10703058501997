.modal.modal-share-level
    width 500px

    .modal-body
        clearfix()

    .meta
        text-align left

    label
        display block
        margin-bottom 5px

    img.preview
        border-radius()
        background-color darken(color-bg, 20%)
        float left
        width 40%

    textarea
        resize none

    .meta
        float right
        width 60%
        padding-left 20px
        box-sizing border-box